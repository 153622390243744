import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import style from "styles/blog.module.css";

// import Image from "next/image";

import React from "react";
import Slider from "react-slick";

import Link from "next/link";

const SimpleSlider = () => {
  const images = [
    {
      id: 4,
      src: "/slider/monthly.png",
      alt: "E-ticaret sitenizi hemen oluşturun",
      leftPart: (
        <>
          <p className="text-base text-gray-500 pb-3">
            Eticex e-ticaret sistemleri ile,
          </p>
          <h2 className="text-2xl font-semibold pb-5 md:text-4xl">
            İhtiyaçlarına göre eticaret paketini oluştur!
          </h2>
          <p>Eticex ile ihtiyaçlarına göre eticaret paketini oluştur, aylık e-ticaret paketleri ile bütçeni zorlamadan eticaret siteni aç.</p>

          <Link href="/aylik-e-ticaret-paketleri.html" className="mt-6 whitespace-nowrap inline-flex items-center justify-center px-4 py-2  rounded shadow-sm text-sm font-400 text-white bg-orange-500 hover:bg-orange-700 focus:outline-none">
            Paketini oluştur

          </Link>

          <Link href="/iletisim.html" className="inline w-4 ml-6 text-sm"> İletişime geç
          </Link>
        </>
      ),
    },
    {
      id: 1,
      src: "/slider/e-ticaret.png",
      alt: "E-ticaret sitenizi hemen oluşturun",
      leftPart: (
        <>
          <p className="text-base text-gray-500 pb-3">
            Eticex e-ticaret sistemleri ile,
          </p>
          <h2 className="text-2xl font-semibold pb-5 md:text-4xl">
            E-ticaret sitenizi hemen oluşturun.
          </h2>
          <p>İhtiyacınız Olan Gelişmiş E-ticaret Paketleri Eticex‘de</p>

          <Link href="/e-ticaret-paketleri.html" className="mt-6 whitespace-nowrap inline-flex items-center justify-center px-4 py-2  rounded shadow-sm text-sm font-400 text-white bg-orange-500 hover:bg-orange-700 focus:outline-none">
            Paketleri İncele

          </Link>

          <Link href="/iletisim.html" className="inline w-4 ml-6 text-sm"> İletişime geç
          </Link>
        </>
      ),
    },
    {
      id: 2,
      src: "/slider/e-ihracat.png",
      alt: "E-ihracat ile Dünyaya açılın",
      leftPart: (
        <>
          <p className="text-base text-gray-500 pb-3">
            Eticex e-ticaret sistemleri ile,
          </p>
          <h2 className="text-2xl font-semibold pb-5 md:text-4xl">
            E-ihracat ile Dünyaya açılın.
          </h2>
          <p>İhtiyacınız olan e-ihracat entegrasyonları Eticex‘de</p>

          <Link href="/e-ihracat.html" className="mt-6 whitespace-nowrap inline-flex items-center justify-center px-4 py-2  rounded shadow-sm text-sm font-400 text-white bg-orange-500 hover:bg-orange-700 focus:outline-none">
            E-ihracat Modülü

          </Link>

          <Link href="/iletisim.html" className="inline w-4 ml-6 text-sm"> İletişime geç
          </Link>
        </>
      ),
    },
    // {
    //   id: 3,
    //   src: "/slider/kargo.png",
    //   alt: "15.90 TL den başlayan kargo anlaşmaları",
    //   leftPart: (
    //     <>
    //       <p className="text-base text-gray-500 pb-3">
    //         Eticex e-ticaret sistemleri ile,
    //       </p>
    //       <h2 className="text-2xl font-semibold pb-5 md:text-4xl">
    //         15.90 TL den başlayan kargo anlaşmaları.
    //       </h2>
    //       <p>İhtiyaç duyduğunuz kargo anlaşmaları Eticex‘de</p>

    //       <Link href="/e-ticaret-paketleri.html" className="mt-6 whitespace-nowrap inline-flex items-center justify-center px-4 py-2  rounded shadow-sm text-sm font-400 text-white bg-orange-500 hover:bg-orange-700 focus:outline-none">
    //           Paketleri İncele

    //       </Link>

    //       <Link href="/iletisim.html" className="inline w-4 ml-6 text-sm"> İletişime geç 
    //       </Link>
    //     </>
    //   ),
    // },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: false
  };
  return (
    <>
      <Slider {...settings}>
        {images.map((item) => (
          <div className="bg-gray-30" key={item.id}>
            <div className="item flex flex-col-reverse w-full items-center lg:grid md:grid lg:grid-cols-2 md:grid-cols-2">
              <div className="px-2 md:px-7 py-5">{item.leftPart}</div>
              <div>
                <img
                  src={item.src}
                  className="float-right"
                  alt="slider img"
                  width={500}
                  height={460}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default SimpleSlider;
