/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { UilPlus, UilMinus } from "@iconscout/react-unicons";
import parse from "html-react-parser";


interface IFaqs {
  faqs: {
    id: number;
    answer: string;
    question: string;
    page: string;
    status: number;
  }[],
  info?: string

}


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Faqs: React.FC<IFaqs> = ({ faqs, info }) => {
  return (
    <>
      {Array.isArray(faqs) && (
        <div className="container mx-auto px-4">
          <div className="w-full py-12  sm:py-16 block md:flex">
            <div className="w-full mb-6  md:w-3/5">
              <p className="font-medium text-[26px] leading-loose">
                Sık Sorulan Sorular
              </p>
              <p className="text-base">
                {info}
              </p>
            </div>

            <dl className="w-full border rounded-xl md:ml-6">
              {faqs.map((faq, index) => (
                <Disclosure key={index}>
                  {({ open }) => (
                    <div className="border-t first:border-t-0 border-gray-200">
                      <dt className="text-base">
                        <Disclosure.Button className="text-left w-full flex justify-between items-center text-gray-400 px-4 py-3">
                          <div className="flex justify-center items-center">
                            <div className={"font-semibold text-[18px] mr-3 border rounded-full min-w-[40px] h-[40px] inline-flex items-center justify-center " + (open ? "text-blue-1000" : "text-gray-600")}>
                              {String(index + 1).padStart(2, '0')}
                            </div>
                            <h2
                              className={classNames(
                                open ? "text-orange-600" : "text-gray-800",
                                "font-semibold text-md  "
                              )}
                            >
                              {faq.question}
                            </h2>
                          </div>
                          <span className="ml-6 h-7 flex items-center leading-10 ">
                            {open ? (
                              <UilMinus className="h-6 w-6 text-gray-800" />
                            ) : (
                              <UilPlus className="h-6 w-6 text-gray-800" />
                            )}
                          </span>


                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-1 mb-4 p-6 mx-4 rounded-md bg-gray-50">
                        <p className="text-sm text-black">{parse(faq.answer)}</p>
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      )}
    </>
  );
}
export default Faqs