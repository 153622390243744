import Image from "next/image";
import Link from "next/link";
type GroupType = {
  groupid: number,
  name: string,
  group_slug: string,
  description: string
}
interface ICard {
  articleid: number,
  subject: string,
  reading: number,
  articlegroup: number,
  url: string,
  group: GroupType[],
  image: {
    image: number
  }
}




const Card: React.FC<ICard> = ({
  // articleid,
  subject,
  // reading,
  // articlegroup,
  url,
  group,
  image
}) => {

  const arrOfDigits = Array.from(String(image.image), Number);
  const image_url = 'https://cdn.eticex.com/e-ticaret/images/blog/' + arrOfDigits.join("/") + '/' + image.image + '.png';


  return (
    <div className="w-full bg-white flex md:block mb-2">
      <Link href={"/blog/" + url} className="w-1/3 md:w-full">
        <Image
          className="rounded object-cover w-full h-24 md:h-48"
          src={image_url}
          // src='https://cdn.eticex.com/e-ticaret/images/blog/1/0/5/105.webp'
          alt={subject}
          width={373}
          height={192}
        />


      </Link>
      <div className=" w-2/3 px-3 md:w-full md:pl-0 md:py-3">
        <Link
          href={"/blog/kategori/" + group[0].group_slug}
          className="text-md leading-10 md:text-sm md:leading-6 ">{group[0].name}
        </Link>
        <Link href={"/blog/" + url}>

          <p className="text-gray-800 font-semibold text-base tracking-tight mb-2 md:text-lg">
            {subject}
          </p>

        </Link>
      </div>
    </div>
  );
};

export default Card;
