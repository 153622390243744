import parse from "html-react-parser";
import React from "react";

interface ISeoText {
  seoText : string
}
const seoText:React.FC<ISeoText> = (data) => {
  return (
    <>
      {data.seoText.length>1 && (
        <div className="bg-gray-200 py-10">
          <div className="container mx-auto px-4 leading-5 md:leading-10 h-64 overflow-y-auto scroll-smooth hover:scroll-auto rounded">
            {parse(data.seoText)}
          </div>
        </div>
      )}
    </>
  );
};

export default seoText;
