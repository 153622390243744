import type { GetServerSideProps, NextPage } from "next";
// import dynamic from "next/dynamic";
import Header from "../components/main/header";
import SimpleSlider from "../components/simpleslider";
import BlogCard from "../components/blog-card";
import Faq from "../components/faq";
import Footer from "../components/main/footer";
// import Subscribe from "../components/subscribe";
import MiniDemo from "../components/miniDemo";
// import DemoForm from "../components/demoform";
import { UilAngleRightB } from "@iconscout/react-unicons";
import Image from "next/image";
import Popup from "../components/popup";
import DemoTalepFormu from "../components/popup-content/demo-talep-formu";

import { customFetch } from "../utilities/fetch";
import React, { useRef, useState } from "react";

import Link from "next/link";

import SeoText from "../components/main/seoText";

type TFaqs = {
  id: number;
  answer: string;
  question: string;
  page: string;
  status: number;
};

type TBlogPosts = {
  articleid: number;
  url: string;
  subject: string;
  articlegroup: number;
  reading: number;
  group: {
    groupid: number;
    name: string;
    group_slug: string;
    description: string;
    active: number;
    color: string;
    group_order: number;
  }[];
  image: {
    image: number;
  };
};

interface IHome {
  faqs: TFaqs[];
  seoText: {
    text: string;
  };
  posts: TBlogPosts[];
}

const Home: NextPage<IHome> = (data) => {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);

  const popUpShow = () => {
    setOpen(true);
  };

  return (
    <>
      <Header
        title="Eticex - E-ticaret çözümleri"
        description="E-ticaret paketleri ve kişiselleştirilebilen yüksek performanslı e-ticaret çözümleri sunan eticaret altyapı sağlayıcı olarak faaliyet göstermektedir."
        setOpen={setOpen}
      />
      <main>
        <div className="container mx-auto px-4">
          <SimpleSlider />

          <h1 className="text-center text-4xl font-semibold leading-10 mt-20">
            E-ticaret sitenizi hemen kurun
          </h1>
          <p className="text-center text-base  text-gray-500 mt-4 ">
            Bildiğiniz tüm e-ticaret sistemlerini unutun, eticex özellikleri ile
            e-ticareti farklı bir boyuta taşıyor.
          </p>

          <div className="flex flex-col divide-y divide-gray-100 mt-6">
            <div className="item flex flex-col w-full items-center md:grid md:grid-cols-2 md:px-10 px-2 py-10 border-1 md:border-0">
              <div className="mb-5 md:mb-0">
                <Image
                  src="https://cdn.eticex.com/e-ticaret/img/modules.png"
                  width={510}
                  height={315}
                  className="float-right"
                  unoptimized={true}
                  alt="E-ticaret sitenizi istediğiniz gibi özelleştirin"
                />
              </div>
              <div className="ml-0 md:ml-10">
                <h2 className="text-2xl md:text-3xl  font-semibold pb-5 leading-normal">
                  E-ticaret sitenizi özelleştirin.
                </h2>
                <p>
                  Eticex‘in geliştirdiği modüllerle e-ticaret yapmak çok kolay !{" "}
                  <br /> <br />
                  * Birlikte Sat, <br />
                  * Kombin, <br />
                  * Yazar - Ayın Yazarları - Yayınevi - Çevirmen, <br />
                  * Beden Tablosu, <br />
                  * İnstafeed, <br />* Blog ve Story gibi onlarca modül sizleri
                  bekliyor.
                </p>
                <Link href="/moduller.html" className="block mt-4 text-sm text-orange-500  hover:text-orange-700">
                  Tüm Modüller <UilAngleRightB className="inline w-4" />

                </Link>
              </div>
            </div>

            <div className="item flex flex-col-reverse w-full items-center md:grid md:grid-cols-2 md:px-10 px-2  py-10">
              <div className="ml-0 md:ml-10">
                <h2 className="text-2xl md:text-3xl  font-semibold pb-5 leading-normal">
                  Güçlü SEO alt yapısı
                </h2>
                <p>
                  Eticex, siteiçi SEO adına gerekli herşeyi kendi bünyesinde
                  barındırır.
                  <br />
                  <br />
                  Robot.txt yönetimi, sitedeki tüm linkleri düzenleyebilme,
                  makro seçenekleri ile sayfalarınıza title ve description
                  girebilme, ürünlerinizi Google‘a pingleme aracı , site
                  haritası (Sitemap), ürün görsellerini içeren İmage Sitemap vb
                  SEO gereksinimlerini eksizsiz karşılar.
                </p>

                <Link href="/e-ticaret-paketleri.html" className="block text-orange-500 mt-4 text-sm hover:text-orange-700">
                  Paketleri İncele
                  <UilAngleRightB className="inline w-4" />

                </Link>
              </div>
              <div className="mb-5 md:mb-0">
                <Image
                  src="https://cdn.eticex.com/e-ticaret/img/seo.png"
                  width={579}
                  height={525}
                  className="float-right w-full"
                  unoptimized={true}
                  alt="SEO Yapısı ve Özellikleri"
                />
              </div>
            </div>

            <div className="item flex  flex-col w-full items-center md:grid md:grid-cols-2 md:px-10 px-2  py-10">
              <div className="mb-5 md:mb-0">
                <Image
                  src="https://cdn.eticex.com/e-ticaret/img/marketplaces.png"
                  width={594}
                  height={501}
                  className="float-right"
                  quality={100}
                  alt="Pazar yeri entegrasyonları"
                />
              </div>
              <div className="ml-0 md:ml-10">
                <h2 className="text-2xl md:text-3xl  font-semibold pb-5 leading-normal">
                  Pazaryeri entegrasyonları ile tüm sistem elinizin altında.
                </h2>
                <p>
                  Eticex‘in kendi alt yapısı üzerinden sunduğu çift yönlü
                  pazaryeri entegrasyonları ile;
                  <br />
                  <br />
                  * Kategorilerinizi eşleyin <br />
                  * Ürün ve stok bilgilerinizi aktarın <br />* Sipariş ve
                  ürünlerinizi tek panelden yöneterek zamandan ve iş gücünden
                  tasarruf edin.
                </p>

                <Link href="/entegrasyonlar.html" className="block text-orange-500 mt-4 text-sm hover:text-orange-700">
                  Tüm Entegrasyonlar <UilAngleRightB className="inline w-4" />

                </Link>
              </div>
            </div>

            <div className="item flex flex-col-reverse w-full items-center md:grid md:grid-cols-2 md:px-10 px-2  py-10">
              <div className="ml-0 md:ml-10">
                <h2 className="text-2xl md:text-3xl  font-semibold pb-5 leading-normal">
                  E-ihracat ile dünyaya açılın.
                </h2>
                <p>
                  Eticex E-ticaret yazılımının bulut alt yapısı ile
                  müşterileriniz sitenizde hızla gezinirken sizler de ülkelere
                  göre fiyat politikanız ile kazançlarınızı arttırın.
                  <br />
                  <br />
                  Global pazarlarda yer alarak ürünlerinizin satışını tüm
                  dünyaya yapabilir, gelişmiş e-ihracat sistemimiz ile kur
                  hareketliliğinden etkilenmeden satışlarınızı
                  arttırabilirsiniz. E-ihracat sistemi sayesinde her ülkeye özel
                  satış fiyatınızı belirleyebilir sınırsız dil seçeneği ile
                  ürünlerinizin tanıtımını tüm dünyaya yapabilirsiniz.
                </p>
                <Link href="/e-ihracat.html" className="block text-orange-500 mt-4 text-sm hover:text-orange-700">
                  E-ihracat modülü <UilAngleRightB className="inline w-4" />

                </Link>
              </div>
              <div className="mb-5 md:mb-0">
                <Image
                  src="https://cdn.eticex.com/e-ticaret/img/e-ihracat.png"
                  width={609}
                  height={508}
                  className="float-right"
                  quality={100}
                  alt="E-ihracat modülü"
                />
              </div>
            </div>

            <div className="item flex  flex-col w-full items-center md:grid md:grid-cols-2 md:px-10 px-2  py-10">
              <div className="mb-5 md:mb-0">
                <Image
                  src="https://cdn.eticex.com/e-ticaret/img/cloud-server.png"
                  width={655}
                  height={465}
                  className="float-right"
                  quality={100}
                  alt="Bulut sunucular ile hızlı ve güvenli"
                />
              </div>
              <div className="ml-0 md:ml-10">
                <h2 className="text-2xl md:text-3xl  font-semibold pb-5 leading-normal">
                  Bulut sunucular ile hızlı ve güvenli
                </h2>
                <p>
                  Eticex; bulut serverlar üzerinde çalışmaktadır. Bu mimaride
                  ziyaretçileriniz, hızlı ve güçlü bir sistem alt yapısı
                  üzerinden alışveriş yaparlar. <br />
                  <br />
                  Sitenizin daha yüksek kaynaklara (CPU, Ram ve Disk) ihtiyaç
                  duyması durumunda dakikalar içerisinde kaynak yükseltme işlemi
                  gerçekleştirilir.
                </p>
                <button
                  className="mt-6 whitespace-nowrap inline-flex items-center justify-center px-4 py-2  rounded shadow-sm text-sm font-400 text-white bg-orange-500 hover:bg-orange-700 focus:outline-none"
                  onClick={popUpShow}
                >
                  Ücretsiz Deneyin
                </button>
              </div>
            </div>
          </div>

          {/* <DemoForm /> */}
          <MiniDemo />
        </div>

        <Faq
          faqs={data.faqs}
          info={
            "Eticex güvencesi ile teslim edilen e-ticaret paketleri sayesinde, siz de e-ticaretteki yerinizi alabilir ve ön plana çıkabilirsiniz"
          }
        />
        <div className="container mx-auto px-4">
          <div className="text-center text-4xl font-semibold leading-10 mt-4">
            E-ticaret ile ilgili herşey blog yazılarımızda!
          </div>
          <p className="text-center text-base  text-gray-500 mt-4 ">
            E-ticaret ekosistemi ile ilgili bilmek istediğiniz her şey
            bloglarımızda, e-ticaret dünyasından haberler ve dahası..
          </p>

          {/* Blog */}

          <div className="md:grid md:grid-cols-3 gap-10 pb-8 pt-10 ">
            {data.posts.map((card, i) => (
              <BlogCard key={i} {...card} />
            ))}
          </div>
        </div>

        {/* <Subscribe /> */}
      </main>

      <SeoText seoText={data.seoText.text} />

      <Popup open={open} setOpen={setOpen}>
        <DemoTalepFormu cancelButtonRef={cancelButtonRef} setOpen={setOpen} />
      </Popup>

      <Footer />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  // const blogPosts = await customFetch(`/blogs/posts/latest`);

  const pagePath = "index";

  // const faqs = await customFetch(`/faqs/` + pagePath);
  // const seo = await customFetch(`/seoText/` + pagePath);

  const [blogPosts, faqs, seo] = await Promise.all(
    [
      customFetch(`/blogs/posts/latest`),
      customFetch(`/faqs/` + pagePath),
      customFetch(`/seoText/` + pagePath)
    ]
  );

  const data = {
    posts: blogPosts.data,
    faqs: faqs.data,
    seoText: seo.data,
  };

  return { props: data };
};

export default Home;
